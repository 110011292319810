import IHeading from "@/components/IHeading"
import ILine from "@/components/ILine"
import IParagraph from "@/components/IParagraph"
import Layout from "@/components/Layout"
import PageHeading from "@/components/PageHeading"
import PageIntro from "@/components/PageIntro"
import Seo from "@/components/seo"
import { graphql, Link, PageProps } from "gatsby"
import * as React from "react"
import img1 from "../images/vectors/undraw_Maintenance_re_59vn.svg"
import img2 from "../images/vectors/undraw_Thought_process_re_om58.svg"
import img3 from "../images/vectors/undraw_Development_re_g5hq.svg"
import img6 from "../images/vectors/undraw_server_cluster_jwwq.svg"
import img5 from "../images/vectors/undraw_monitor_iqpq.svg"
import img4 from "../images/vectors/undraw_connected_world_wuay.svg"
import { ShoppingBasket } from "@material-ui/icons"
import { StaticImage } from "gatsby-plugin-image"
import { PeopleOutline } from "@material-ui/icons"
import { Timeline } from "@material-ui/icons"
import Testimonial from "@/components/Testimonial"
import FAQs from "@/components/FAQs"
import CallToAction from "@/components/CallToAction"
import { AttachMoney } from "@material-ui/icons"
import { LocalHospital } from "@material-ui/icons"
import { CastForEducation } from "@material-ui/icons"
import { Cloud } from "@material-ui/icons"
interface BuildYourMvp {
  mdx: {
    body: string
    excerpt: string
    frontmatter: {
      title: string
      heading: string
      iconName: string
      color?: string
    }
  }
}

const BuildYourMvpPage: React.FC<PageProps<BuildYourMvp>> = ({ data }) => {
  const { frontmatter, excerpt } = data.mdx
  const { title, heading } = frontmatter
  const feature = [
    {
      title: "MVP Strategy and Planning",
    },
    {
      title: " Rapid Prototyping",
    },
    {
      title: "Full-stack MVP Development",
    },
    {
      title: "UI/UX Design for MVPs",
    },
    {
      title: "API Development and Integration",
    },
    {
      title: "Database Design and Management",
    },
    {
      title: "Cloud Infrastructure Setup",
    },
    {
      title: "Analytics Integration",
    },
    {
      title: "Post-launch Support and Iteration",
    },
  ]
  const Process = [
    {
      title: "Discovery and Planning",
      content:
        "In-depth consultation to understand your vision and goals. Market research and competitor analysis. Feature prioritization and MVP scope definition. Technology stack selection. Project roadmap and timeline creation.",
    },
    {
      title: "Design and Development",
      content:
        "Wireframing and prototyping. User interface design. Agile development sprints. Regular progress updates and demonstrations. Continuous integration and deployment.",
    },
    {
      title: "Testing and Quality Assurance",
      content:
        "Functional testing of core features. User acceptance testing. Performance and security testing. Bug fixing and refinement.",
    },
    {
      title: "Launch and Post-launch Support",
      content:
        "Deployment to production environment. Initial user onboarding support. Performance monitoring and optimization. Gathering and analyzing user feedback. Iterative improvements and feature additions.",
    },
  ]
  const Core = [
    {
      title: "Custom MVP Development",
      content:
        "We build tailored MVPs that align perfectly with your business goals and target audience needs, using the most suitable technologies for your project.",
      imgSrc: img1,
    },
    {
      title: "Rapid Prototyping",
      content:
        "Create interactive prototypes quickly to visualize your product, test assumptions, and pitch to investors before full development.",
      imgSrc: img2,
    },
    {
      title: "Third-party Integrations",
      content:
        "Enhance your MVP's functionality by integrating essential third-party services, APIs, and tools",
      imgSrc: img3,
    },
    {
      title: " Performance Optimization",
      content:
        "Ensure your MVP runs smoothly and efficiently, providing a great user experience from day one.",
      imgSrc: img6,
    },
    {
      title: " Security Implementation",
      content:
        "Implement basic yet robust security measures to protect your MVP and user data.",
      imgSrc: img5,
    },
    {
      title: " Mobile-first Development",
      content:
        "Build mobile-responsive web apps or native mobile apps to capture the growing mobile user base.",
      imgSrc: img4,
    },
  ]
  const helpItems = [
    {
      icon: <AttachMoney />,
      content: "FinTech",
    },
    {
      icon: <LocalHospital />,
      content: " HealthTech",
    },
    {
      icon: <CastForEducation />,
      content: " EdTech",
    },
    {
      icon: <ShoppingBasket />,
      content: "E-commerce",
    },
    {
      icon: <Cloud />,
      content: "SaaS Platforms",
    },
  ]
  const items = [
    {
      imgSrc: <PeopleOutline />,
      title: "Team Expertise",
      desc: " Our team of seasoned developers, designers, and product managers brings years of experience in building successful MVPs across various industries.",
    },
    {
      imgSrc: <Timeline />,
      title: "Proven Track Record",
      desc: "We've helped numerous startups go from idea to funded companies through our efficient MVP development process.",
    },
  ]
  const faqData = [
    {
      question: "How long does it take to build an MVP?",
      answer:
        "Typically, we can deliver a functional MVP in 6-12 weeks, depending on the complexity of the project.",
    },
    {
      question: "How much does MVP development cost?",
      answer:
        "Costs vary based on features and complexity. We offer flexible pricing models and can work within most startup budgets.",
    },
    {
      question: "Can you help with product strategy and market validation?",
      answer:
        "Absolutely! We offer product strategy consulting and can help you validate your idea before and during MVP development.",
    },
    {
      question: "What happens after the MVP is launched?",
      answer:
        "We provide ongoing support, help analyze user feedback, and can continue iterating on your product based on market response.",
    },
    {
      question: "Do you offer any post-MVP scaling services?",
      answer:
        "Yes, we can help scale your MVP into a full-fledged product as your business grows.",
    },
  ]

  return (
    <>
      <Layout>
        <Seo
          title="Build Your MVP Launching Your Startup Vision Fast & Efficiently"
          description={heading}
        />
        <div data-aos="fade-up">
          <PageHeading title={title} description={heading}></PageHeading>
        </div>
        <div data-aos="fade-up">
          <PageIntro>{excerpt}</PageIntro>
        </div>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div className="max-w-5xl" data-aos="fade-up">
              <IParagraph>
                At Easesol, we understand that time and resources are critical
                for startups. Our approach to MVP development focuses on speed,
                efficiency, and value, helping you bring your product to market
                faster and smarter.
              </IParagraph>
            </div>
          </div>
        </section>

        <section className="w-full my-24">
          <div className="container mx-auto">
            <div className="w-full">
              <div>
                <div data-aos="fade-up">
                  <IHeading align="left">Range of Services Offered:</IHeading>
                </div>
              </div>
              <div className="grid grid-cols-1 py-10 -mx-1 overflow-hidden md:grid-cols-2 gap-x-10">
                {feature.map((item: any, index: number) => {
                  return (
                    <div
                      className="relative w-full px-6 my-6 overflow-hidden group"
                      key={index}
                      data-aos="fade-up"
                    >
                      <IHeading size="three">{item.title}</IHeading>
                      <IParagraph>{item.content}</IParagraph>
                      <div
                        className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                        style={{ zIndex: "-1" }}
                      ></div>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        </section>
        <section className="w-full mt-24">
          <div className="container mx-auto work-flow-container">
            <div className="mb-2" data-aos="fade-up">
              <IHeading size="subTitle">Our Process</IHeading>
            </div>
            <ILine align="left" />
            <div className="grid w-full grid-cols-1 gap-16 py-16 mx-auto work-flow-box md:grid-cols-2 2xl:grid-cols-3 md:gap-10">
              {Process.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center justify-center p-16 text-3xl text-center text-white shadow-xl group work-flow-item rounded-xl"
                    data-aos="fade-up"
                  >
                    {item.title}
                    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center px-4 py-6 text-base text-center duration-300 shadow-2xl opacity-0 rounded-xl bg-primary-50 dark:bg-primary-200 text-primary-500 dark:text-primary-600 lg:text-lg group-hover:opacity-100">
                      {item.content}
                    </div>
                    <div className="absolute left-0 right-0 flex items-center justify-center mx-auto text-3xl text-center border-4 border-white rounded-full w-14 h-14 bg-primary-700 -top-8 md:-top-5 md:right-auto md:-left-5">
                      {index + 1}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </section>
        <section className="w-full my-16">
          <div className="container pb-8 mx-auto">
            <div data-aos="fade-up">
              <IHeading align="left">Core Services</IHeading>

              <div className="">
                <ILine align="left" />
              </div>
            </div>

            {Core.map((item: any, index: number) => {
              return (
                <>
                  <div
                    className={`flex flex-col ${
                      index % 2 === 1 ? "lg:flex-row-reverse" : "lg:flex-row"
                    } items-center mb-20 gap-x-5 md:gap-x-10 xl:gap-x-20 gap-y-10`}
                    key={index}
                  >
                    <div className="w-full lg:w-1/2">
                      <div data-aos="fade-up">
                        <IHeading size="two">{item.title}</IHeading>
                      </div>
                      <div data-aos="fade-up">
                        <IParagraph>{item.content}</IParagraph>
                      </div>
                    </div>
                    <div
                      className="w-full max-w-lg lg:w-1/2 lg:max-w-max"
                      data-aos="fade-up"
                    >
                      <img src={item.imgSrc} alt={item.title} />
                    </div>
                  </div>
                </>
              )
            })}
          </div>
        </section>
        <section className="w-full my-16">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Industry Expertise</IHeading>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="text-center">
                We've successfully delivered MVPs across various industries,
                including:
              </IParagraph>
            </div>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-2 mt-14">
              {helpItems.map((item: any, index: number) => {
                return (
                  <div
                    className="relative flex flex-col items-center p-10 overflow-hidden border-b-2 rounded-md shadow-xl group border-slate-600 dark:border-primary-400 dark:bg-primary-900"
                    key={index}
                    data-aos="fade-up"
                  >
                    <div className="z-10 mb-6 startup-help-icon-box">
                      {item.icon}
                    </div>
                    <p className="absolute text-2xl lg:text-3xl  items-center text-center top-0 bottom-0 left-0 right-0 z-0 p-16  text-gray-200 duration-300 opacity-0 bg-slate-600 dark:bg-primary-600 group-hover:opacity-100 group-hover:z-20">
                      {item.content}
                    </p>
                  </div>
                )
              })}
            </div>
            <div className="py-12">
              <div data-aos="fade-up">
                <IHeading align="text-left" size="subTitle">
                  Success Story: HealthTrack
                </IHeading>
              </div>
              <div data-aos="fade-up">
                <IParagraph align="left">
                  We developed an MVP for HealthTrack, a digital health
                  monitoring startup. The result:
                </IParagraph>
              </div>
              <div className="grid grid-cols-3">
                <div
                  className="relative w-full px-6 my-6 overflow-hidden group"
                  data-aos="fade-up"
                >
                  <IHeading size="three">MVP launched in just 8 weeks</IHeading>
                  <div
                    className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                    style={{ zIndex: "-1" }}
                  ></div>
                </div>
                <div
                  className="relative w-full px-6 my-6 overflow-hidden group"
                  data-aos="fade-up"
                >
                  <IHeading size="three">
                    Secured $2M in seed funding within 3 months of launch
                  </IHeading>
                  <div
                    className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                    style={{ zIndex: "-1" }}
                  ></div>
                </div>
                <div
                  className="relative w-full px-6 my-6 overflow-hidden group"
                  data-aos="fade-up"
                >
                  <IHeading size="three">
                    {" "}
                    Acquired 10,000 beta users in the first month
                  </IHeading>
                  <div
                    className="absolute top-0 bottom-0 left-0 w-3 duration-300 bg-primary-200 dark:bg-primary-700 group-hover:w-full"
                    style={{ zIndex: "-1" }}
                  ></div>
                </div>
              </div>

              <div className="">
                <ILine align="left" />
              </div>
            </div>
          </div>
        </section>
        <section className="py-16 my-14 bg-primary-200 dark:bg-primary-600  rounded-xl">
          <div className="container mx-auto">
            <div className="max-w-2xl mx-auto" data-aos="fade-up">
              <IHeading align="text-center">Technology Partners</IHeading>
              <IParagraph align="text-center">
                We leverage cutting-edge technologies to build robust and
                scalable MVPs:
              </IParagraph>
            </div>
            <ILine align="center" />

            <div
              className="flex justify-around flex-wrap md:flex-nowrap md:justify-evenly gap-4 items-center space-y-2.5 pt-4 pb-8"
              data-aos="fade-up"
            >
              <div className="techstack-icon-box group item">
                <Link to="https://react.dev/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/react.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="React"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://vuejs.org/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/vuejs.svg"
                    className="black-stack-icon duration-200 group-hover:scale-125 "
                    alt="Vue.js"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item w-26">
                <Link to="https://angular.dev/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/angular.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="Angular"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://nodejs.org/en" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/nodejs.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="Nodejs"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://www.python.org/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/python.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="Python"
                  />
                </Link>
              </div>
            </div>
            <div
              className="flex justify-around flex-wrap md:flex-nowrap md:justify-evenly gap-4 items-center space-y-2.5 pt-4 pb-8"
              data-aos="fade-up"
            >
              <div className="techstack-icon-box group item">
                <Link to="https://rubyonrails.org/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/ruby_on_rails.svg"
                    className="black-stack-icon duration-200 group-hover:scale-125 "
                    alt="rubyonrails"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item w-26">
                <Link to="https://www.flutter.dev/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/flutter.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="flutter"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://www.mongodb.com/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/mongodb.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="mongodb"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://www.postgresql.org/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/PostgreSQL.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="PostgreSQL"
                  />
                </Link>
              </div>
            </div>
            <div
              className="flex justify-around flex-wrap md:flex-nowrap md:justify-evenly gap-4 items-center space-y-2.5 pt-4 pb-8"
              data-aos="fade-up"
            >
              <div className="techstack-icon-box group item">
                <Link to="https://aws.amazon.com/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/aws.svg"
                    className="black-stack-icon duration-200  group-hover:scale-125 "
                    alt="AWS"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item w-26">
                <Link to="https://cloud.google.com/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/google_cloud-ar21.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="Google Cloud"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://azure.microsoft.com/en-us" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/azure.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="azure"
                  />
                </Link>
              </div>
            </div>
            <div
              className="flex justify-around flex-wrap md:flex-nowrap md:justify-evenly gap-4 items-center space-y-2.5 pt-4 pb-8"
              data-aos="fade-up"
            >
              <div className="techstack-icon-box group item">
                <Link to="https://www.docker.com/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/docker.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="docker"
                  />
                </Link>
              </div>
              <div className="techstack-icon-box group item">
                <Link to="https://kubernetes.io/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/kubernetes.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="kubernetes"
                  />
                </Link>
              </div>
            </div>
            <div
              className="flex justify-around flex-wrap md:flex-nowrap md:justify-evenly gap-4 items-center space-y-2.5 pt-4 pb-8"
              data-aos="fade-up"
            >
              <div className="techstack-icon-box group item">
                <Link to="https://www.jenkins.io/" target="_blank">
                  <StaticImage
                    src="../images/techs-icons/jenkins.svg"
                    className="black-stack-icon pointer-events-none duration-200 group-hover:scale-125 block dark:hidden"
                    alt="jenkins"
                  />
                </Link>
              </div>
            </div>
            <div data-aos="fade-up">
              <IParagraph align="text-center">
                We also partner with leading design tools, analytics platforms,
                and project management
                <span>
                  Salesforce Commerce Cloud, formerly known as Demandware, is a
                  leading cloud-based e-commerce platform designed to empower
                  businesses of all sizes to create seamless, personalized
                  shopping experiences across various channels.
                </span>{" "}
                solutions to ensure smooth development and delivery.
              </IParagraph>
            </div>
          </div>
        </section>
        <section className="w-full my-20">
          <div className="container mx-auto">
            <div data-aos="fade-up">
              <IHeading align="text-center">Why Choose Us</IHeading>
            </div>
            <div className="grid grid-cols-1 mb-12 lg:grid-cols-2 gap-x-10 gap-y-28 mt-36">
              {items.map((item: any) => {
                return (
                  <div
                    className="flex flex-col items-center  p-5 rounded-lg shadow-xl bg-slate-700"
                    data-aos="fade-up"
                  >
                    <div className="flex items-center justify-center mb-6 -mt-20 overflow-hidden rounded-full icon-box w-28 md:w-40 h-28 md:h-40 bg-slate-200">
                      {item.imgSrc}
                    </div>
                    <IHeading size="subHeader" color="white">
                      {item.title}
                    </IHeading>
                    <div className="mt-3 mb-6">
                      <ILine />
                    </div>
                    <IParagraph color="gray">
                      <ul className="list-disc space-y-3 px-16">
                        {item.desc
                          .split(".")
                          .filter((sentence: string) => sentence.trim() !== "") // Filter out any empty strings
                          .map(
                            (
                              sentence: string,
                              index: React.Key | null | undefined
                            ) => (
                              <li key={index}>{sentence.trim()}.</li>
                            )
                          )}
                      </ul>
                    </IParagraph>
                  </div>
                )
              })}
            </div>
            <div data-aos="fade-up">
              <IHeading align="text-left" size="subTitle">
                Client Testimonial
              </IHeading>
            </div>
            <Testimonial
              review="Your Easesol turned our vision into a working product in record time. Their MVP development expertise was crucial in helping us secure funding and gain early traction."
              name="Alex K"
              type="TechStartup"
              position="CEO"
              reviewLink="https://www.g2.com/products/posthog/reviews/posthog-review-4887279"
              reviewLinkText="Unknown"
            />
          </div>
        </section>
        <FAQs faqs={faqData} />
        <CallToAction
          title="Ready to Build Your MVP?"
          description="Don't let your great idea remain just an idea. Let our experts help you build a compelling MVP that will set your startup on the path to success"
          cta="Get Started Now"
          ctaTo="/contact-us"
        />
      </Layout>
    </>
  )
}

export const query = graphql`
  query BuildYourMvpPageQuery {
    mdx(slug: { eq: "build-your-mvp" }) {
      body
      excerpt(pruneLength: 160)
      frontmatter {
        title
        heading
        iconName
        color
      }
    }
  }
`

export default BuildYourMvpPage
